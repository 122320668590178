import * as request from '@/utils/request'

/**
 * 自动生成 api代码【模块名：】
 * 生成日期：2025/02/10 14:44:06
 * 生成路径: src/api/artist_compare.js
 * 生成人：超级管理员
 */

/**
 * @description 获取列表
 * @param {*} data
 * @returns
 */
export function getArtistCompareList(data) {
  return request.post('/artist_compare/list', data)
}

/**
 * @description 新增
 * @param {*} data
 * @returns
 */
export function addArtistCompare(data) {
  return request.post('/artist_compare/add', data)
}

/**
 * @description 修改
 * @param {*} data
 * @returns
 */
export function editArtistCompare(data) {
  return request.post('/artist_compare/edit', data)
}

/**
 * @description 获取详情
 * @param id
 * @returns
 */
export function getArtistCompareInfo(id) {
  let data = { id: id }
  return request.post('/artist_compare/get_info', data)
}

/**
 * @description 根据id删除单条信息
 * @param id
 * @returns
 */
export function deleteArtistCompare(id) {
  let data = { id: id }
  return request.post('/artist_compare/delete', data)
}

/**
 * @description 同步星广汇昵称
 * @returns
 */
export function syncAdArtistNickname() {
  return request.post('/artist_compare/sync_ad_nickname', {})
}