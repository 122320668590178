<template>
  <div>
    <PageHeaderLayout>
      <div class='main-page-content'>
        <div class='table-search'>
          <el-form :model='searchCondition' inline size='mini'>
            <el-form-item label='红人Id(星象仪)'>
              <el-input v-model='searchCondition.artist_id' placeholder='红人Id' clearable></el-input>
            </el-form-item>
            <el-form-item label='红人昵称(星象仪)'>
              <el-input v-model='searchCondition.nickname' placeholder='红人昵称' clearable></el-input>
            </el-form-item>
            <el-form-item label='红人Id(星广汇)'>
              <el-input v-model='searchCondition.ad_artist_id' placeholder='红人Id' clearable></el-input>
            </el-form-item>
            <el-form-item label='红人昵称(星广汇)'>
              <el-input v-model='searchCondition.ad_nickname' placeholder='红人昵称' clearable></el-input>
            </el-form-item>

            <el-form-item label='创建时间'>
              <el-date-picker
                v-model='searchCondition.date_range'
                type='daterange'
                align='right'
                unlink-panels
                range-separator='至'
                start-placeholder='开始日期'
                end-placeholder='结束日期'
                value-format='yyyy-MM-dd'
                :picker-options='pickerOptions'>
              </el-date-picker>
            </el-form-item>
            <el-form-item>
              <el-button type='primary' icon='el-icon-search' :disabled='loading' :loading='loading' @click='handleQuery'>搜索</el-button>
            </el-form-item>
          </el-form>
        </div>
        <!--        操作栏-->
        <el-row class='mb8' :gutter='10'>
          <el-col :span='1.5'>
            <el-button type='primary' icon='el-icon-plus' v-if='hasAuthority("artist_compare__add")' @click='handleAdd'>新增</el-button>
          </el-col>
          <el-col :span='1.5'>
            <el-button type='warning' icon='el-icon-refresh' @click='handleSync' :disabled='loadingCommit' :loading='loadingCommit'>同步昵称</el-button>
          </el-col>
        </el-row>
        <!--    渲染表格-->
        <div class='default-table'>
          <MlPageTable :all-columns='defaultColumns'
                       :table-name='tableName'
                       :data-list='dataList'
                       :limit.sync='pageData.page_size'
                       :page.sync='pageData.current_page'
                       :total.sync='total'
                       :height='height'
                       highlight-current-row
                       :row-class-name='tableRowClassName'
                       @row-click='rowClickEvent'
                       @getList='getList'>
            <el-table-column align='center' width='120' label='操作'>
              <template slot-scope='{row,$index}'>
                <el-button type='text' icon='el-icon-edit' v-if='hasAuthority("artist_compare__edit")' @click='handleEdit(row)'>修改</el-button>
                <el-button type='text' icon='el-icon-delete' v-if='hasAuthority("artist_compare__delete")' @click='handleDelete(row,$index)'>删除</el-button>
              </template>
            </el-table-column>
          </MlPageTable>
        </div>
      </div>

      <!-- 添加或修改对话框 -->
      <el-dialog :title='title + "红人对照"' :visible.sync='open' width='800px' append-to-body
                 :close-on-click-modal='false'>
        <el-form v-loading="DialogLoading" ref='form' :model='form' :rules='rules' label-width='120px'>
          <el-form-item label='红人(星象仪)' prop='artist_id'>
            <select-artist :placeholder="'红人(星象仪)'" :value.sync="form.artist_id"
                           :label.sync="form.nickname"/>
          </el-form-item>
          <el-form-item label='红人(星广汇)' prop='ad_artist_id'>
            <select-ad-artist :placeholder="'红人(星广汇)'" :value.sync="form.ad_artist_id"
                              :label.sync="form.ad_nickname"/>
          </el-form-item>
        </el-form>
        <div slot='footer' class='dialog-footer'>
          <el-button type='primary' @click='submitForm' :disabled='loadingCommit' :loading='loadingCommit'>确 定
          </el-button>
          <el-button @click='cancel'>取 消</el-button>
        </div>
      </el-dialog>
    </PageHeaderLayout>
  </div>
</template>

<script>
import MlPageTable from '@/components/common/MlPageTable'
import PageHeaderLayout from '@/layouts/PageHeaderLayout'
import ExportBtn from '@/components/export/ExportBtn'
import {
  addArtistCompare,
  deleteArtistCompare,
  editArtistCompare,
  getArtistCompareInfo,
  getArtistCompareList, syncAdArtistNickname
} from '@/api/artist_compare'
import SelectArtist from '@/components/select/artist.vue'
import SelectAdArtist from '@/components/select/ad-artist.vue'
import {hasAuthority} from "@/utils/hasAuthority";

export default {
  name: 'ArtistCompareList',
  components: { SelectAdArtist, SelectArtist, ExportBtn, PageHeaderLayout, MlPageTable },
  data() {
    return {
      tableName: 'artist_compare',
      loading: false,
      loadingCommit: false,
      dataList: [],
      total: 0,
      height: 800,
      searchCondition: { date_range: [],artist_id: null, nickname: null, ad_artist_id: null, ad_nickname: null},
      pageData: { current_page: 1, page_size: 20 },
      orderBy: { id: 'desc' },
      pickerOptions: {
        shortcuts: [{
          text: '本月',
          onClick(picker) {
            picker.$emit('pick', [new Date(), new Date()])
          }
        }, {
          text: '今年至今',
          onClick(picker) {
            const end = new Date()
            const start = new Date(new Date().getFullYear(), 0)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '最近六个月',
          onClick(picker) {
            const end = new Date()
            const start = new Date()
            start.setMonth(start.getMonth() - 6)
            picker.$emit('pick', [start, end])
          }
        }]
      },
      defaultColumns:  [{ hide: false, prop: 'id', label: '序号', width: 120, sortable: true, fixed: false },
        { hide: false, prop: 'artist_id', label: '红人ID(星象仪)', width: 120, sortable: false, fixed: false },
        { hide: false, prop: 'nickname', label: '红人昵称(星象仪)', width: 120, sortable: false, fixed: false },
        { hide: false, prop: 'ad_artist_id', label: '红人ID(星广汇)', width: 120, sortable: false, fixed: false },
        { hide: false, prop: 'ad_nickname', label: '红人昵称(星广汇)', width: 120, sortable: false, fixed: false },
        { hide: false, prop: 'created_at', label: '创建时间', width: 120, sortable: true, fixed: false }],
      // 弹出层标题
      title: '添加/修改-',
      // 是否显示弹出层
      open: false,
      // 表单校验
      rules:{artist_id: [{ required: true, message: '(星象仪)红人Id不能为空', trigger: 'blur' }],
        nickname: [{ required: true, message: '红人昵称不能为空', trigger: 'blur' }],
        ad_artist_id: [{ required: true, message: '(星广汇)红人Id不能为空', trigger: 'blur' }],
        ad_nickname: [{ required: true, message: '红人昵称不能为空', trigger: 'blur' }]},
      // 表单参数
      form: {},
      //是否显示弹出层:导入框
      openImport: false,
      //筛选条件缓存key
      pageSearchKey: 'page_search_key:artist_compare',
      DialogLoading: true
    }
  },
  methods: {
    hasAuthority,
    // eslint-disable-next-line no-unused-vars
    rowClickEvent(row, column, event) {
      // this.$notify.info(`数据ID：${row.id}`)
    },
    // eslint-disable-next-line no-unused-vars
    tableRowClassName({ row, rowIndex }) {
      return 'default-table-row'
    },
    handleSearchCondition() {
      let condition = {}
      Object.keys(this.searchCondition).forEach((field) => {
        if (this.searchCondition[field]) {
          condition[field] = this.searchCondition[field]
        }
      })

      // 分页 +排序
      Object.assign(condition, this.pageData, { orderBy: this.orderBy })

      return condition
    },
    handleQuery() {
      this.pageData.current_page = 1
      this.getList()
      //缓存筛选条件，刷新后可以继续使用
      sessionStorage.setItem(this.pageSearchKey, JSON.stringify(this.searchCondition))
    },
    async getList() {
      this.dataList = []
      this.loading = true
      let params = this.handleSearchCondition()
      let { list, pages } = await getArtistCompareList(params)
      // this.dataList = [...this.dataList, ...list]
      this.dataList = list
      this.pageData.current_page = pages.current_page || 1
      this.pageData.page_size = pages.page_size || 100
      this.total = pages.total
      this.loading = false
    },
    /** 删除按钮 */
    handleDelete(row, index) {
      if (row.id) {
        //真实删除
        this.$confirm('此操作将删除（序号[' + row.id + ']）该行数据, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          let id = this.delRow(row.id)
          if (id) {
            this.$message({type: 'success', message: '删除成功'})
          } else {
            this.$message({type: 'error', message: '删除失败'})
          }
          this.dataList.splice(index, 1)
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
      } else {
        this.tableData.splice(index, 1)
      }
    },
    async delRow(id) {
      return await deleteArtistCompare(id)
    },
    /** 修改按钮 */
    handleAdd() {
      this.open = true
      this.DialogLoading = true
      this.title = '添加-'
      this.form = {}
      this.DialogLoading = false
    },
    /** 修改按钮 */
    async handleEdit(row) {
      this.open = true
      this.DialogLoading = true
      this.title = '修改-'
      let { info } = await getArtistCompareInfo(row.id)
      this.form = { ...info }
      delete this.form.created_at
      delete this.form.updated_at
      this.DialogLoading = false
    },

    /** 提交按钮 */
    async submitForm() {
      this.$refs['form'].validate(valid => {
        if (valid) {
          this.loadingCommit = true
          if (this.form.id) {
            editArtistCompare(this.form).then(id => {
              if (id) {
                if (!this.form.id)
                  this.msgSuccess('新增成功')
                else
                  this.msgSuccess('修改成功')

                this.form.id = id
                this.open = false
                this.getList()
              } else {
                this.msgError('操作失败')
              }
              setTimeout(() => {
                this.loadingCommit = false
              }, 500)
            })
          } else {
            addArtistCompare(this.form).then(id => {
              if (id) {
                if (!this.form.id)
                  this.msgSuccess('新增成功')
                else
                  this.msgSuccess('修改成功')

                this.form.id = id
                this.open = false
                this.getList()
              } else {
                this.msgError('操作失败')
              }
              setTimeout(() => {
                this.loadingCommit = false
              }, 500)
            })
          }
        }
      })
    },
    /** 同步 */
    async handleSync() {
      this.loadingCommit = true
      syncAdArtistNickname().then( data => {
        setTimeout(() => {
          this.loadingCommit = false
          this.msgSuccess(data)
        }, 500)
      })
    },
    // 取消按钮
    cancel() {
      this.open = false
      this.form = {}
    },
  },
  created() {
    if (sessionStorage.getItem(this.pageSearchKey)) {
      this.searchCondition = JSON.parse(sessionStorage.getItem(this.pageSearchKey))
    }
    this.handleQuery()
  }
}
</script>

<style scoped>

</style>